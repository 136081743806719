import styled, { css } from "styled-components";

import Pill from "./Pill.jsx";

export const StyledPill = styled(Pill)`
  padding: 1rem 1.3rem;
  border-radius: 2rem;
  display: inline-block;
  cursor: ${(props) => props.readOnly ? "default" : "pointer"};
  font-family: ${(props) => props.theme.FONTS.families.lato};
  color: ${(props) => props.theme.COLOURS.PRIMARY.base};
  font-size: ${(props) => props.theme.FONTS.sizes[10]};
  font-weight: ${(props) => props.theme.FONTS.weights[70]};
  line-height: ${(props) => props.theme.FONTS.lineHeights[20]};
  letter-spacing: 0.075rem;
  text-transform: uppercase;
  transition: background-color 0.1s ease-in;

  &:not(:last-of-type) {
    margin-right: 0.5rem;
  }

  p {
    margin: 0;
  }

  &.pill-disabled {
    background-color: ${(props) => props.theme.COLOURS.SECONDARY.shades[20]};
    pointer-events: none;
  }

  ${(props) =>
    props.light &&
    css`
      background-color: ${props.theme.COLOURS.PRIMARY.shades[5]};
      cursor: default;
    `}

  ${(props) =>
    props.dark &&
    css`
      background-color: ${props.theme.COLOURS.PRIMARY.shades[80]};
      color: ${props.theme.COLOURS.WHITE};
    `}
  
  ${(props) =>
    props.transparent &&
    css`
      background-color: ${props.theme.COLOURS.TRANSPARENCIES.primary};
    `}

  ${(props) =>
    props.orange &&
    css`
      background-color: ${props.theme.COLOURS.ORANGE.base};
      color: ${props.theme.COLOURS.WHITE};
    `}

    ${(props) =>
    props.blue &&
    css`
      background-color: ${props.theme.COLOURS.BLUE};
      color: ${props.theme.COLOURS.WHITE};
    `}

    ${(props) =>
    props.red &&
    css`
      background-color: ${props.theme.COLOURS.RED.base};
      color: ${props.theme.COLOURS.WHITE};
    `}

  ${(props) =>
    props.green &&
    css`
      background-color: ${props.theme.COLOURS.GREEN.base};
      color: ${props.theme.COLOURS.WHITE};
    `}

    ${(props) =>
    props.primary &&
    css`
      background-color: ${props.theme.COLOURS.PRIMARY.base};
      color: ${props.theme.COLOURS.WHITE};
    `}
  
    ${(props) =>
    props.accent &&
    css`
      background-color: ${props.theme.COLOURS.ACCENT.base};
      color: ${props.theme.COLOURS.WHITE};
    `}
  
  ${(props) =>
    props.large &&
    css`
      padding: 1.1rem 2rem;
      background-color: ${props.theme.COLOURS.PRIMARY.base};
      span {
        margin-right: 0.5rem;
        svg {
          display: block;
        }
      }
      p {
        font-size: ${props.theme.FONTS.sizes[40]};
        display: flex;
        align-items: center;
        color: ${props.theme.COLOURS.WHITE};
        &:hover {
          cursor: pointer;
        }
      }
    `}

  ${(props) =>
    props.small &&
    css`
      padding: 0.55rem 1rem;
      background-color: ${props.theme.COLOURS.PRIMARY.base};
      span {
        margin-right: 0.25rem;
        svg {
          display: block;
        }
      }
      p {
        font-size: ${props.theme.FONTS.sizes[20]};
        display: flex;
        align-items: center;
        color: ${props.theme.COLOURS.WHITE};
        &:hover {
          cursor: pointer;
        }
      }
    `}
`;

export default StyledPill;
