import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { copyToClipboard } from "../../../shared/utility";

const RegistrationPlate = ({ className, registrationNumber, allowCopyToClipboard = false }) => {

  return (
    <span className={className} onClick={() => allowCopyToClipboard ? copyToClipboard(registrationNumber) : null}>
      {registrationNumber}
    </span>
  );
};

const StyledRegistrationPlate = styled(RegistrationPlate)`
  background-color: ${(props) => props.theme.COLOURS["YELLOW"]};
  border-radius: 0.2rem;
  color: ${(props) => props.theme.COLOURS["BLACK"]};
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.4rem;
  font-family: ${(props) => props.theme.FONTS.families.lato};
  padding: 0.5rem;
  text-transform: uppercase;

  ${(props) =>
    props.large &&
    css`
    font-size: 2rem;
  `}

  ${(props) =>
    props.allowCopyToClipboard &&
    css`
      cursor: copy
  `}
`;

RegistrationPlate.propTypes = {
  className: PropTypes.string,
  registrationNumber: PropTypes.string,
};

export default StyledRegistrationPlate;
