export const BREADCRUMB_LABEL = "Back to Claims Home";
export const NO_CLAIMS_FOUND = "No Claims Found";
export const NO_CAMPAIGNS_FOUND = "No Campaigns Found";

const VENDOR_CAMPAIGN_MANAGEMENT_ROOT = "/vendor/campaign-management";
const VENDOR_CLAIMS_ROOT = "/vendor/claims";
const VENDOR_VEHICLE_ROOT = "/vendor/vehicle";

export const vendorPaths = {
  CLAIMS: `${VENDOR_CLAIMS_ROOT}`,
  NEW_CLAIMS: `${VENDOR_CLAIMS_ROOT}/new-claims`,
  CLAIMS_IN_PROGRESS: `${VENDOR_CLAIMS_ROOT}/claims-in-progress`,
  CALLBACK_REQUESTED: `${VENDOR_CLAIMS_ROOT}/callback-requested`,
  COMPLETED_CLAIMS: `${VENDOR_CLAIMS_ROOT}/completed-claims`,
  OFFER_ACTION_REQUIRED: `${VENDOR_CLAIMS_ROOT}/offer-action-required`,

  CAMPAIGN_MANAGEMENT: `${VENDOR_CAMPAIGN_MANAGEMENT_ROOT}`,
  CREATE_CAMPAIGNS: `${VENDOR_CAMPAIGN_MANAGEMENT_ROOT}/create-campaign`,
  NEW_CAMPAIGNS: `${VENDOR_CAMPAIGN_MANAGEMENT_ROOT}/new-campaigns`,
  
  VEHICLE_RECORD:`${VENDOR_VEHICLE_ROOT}/:id`,
};

export const filterTypes = {
  IN_PROGRESS: "InProgress",
  NEW_CLAIMS: "NewClaims",
  CALLBACKS: "Callbacks",
  RECENT_COMPLETED: "RecentCompleted",
  OFFER_ACTION_REQUIRED: "OfferActionRequired",
};

export const NEW_CLAIMS = {
  PAGE_TITLE: "New Claim Requests",
  PAGE_SUB_TITLE: "Summary Overview",
};

export const CLAIMS_IN_PROGRESS = {
  PAGE_TITLE: "Claims in Progress",
  PAGE_SUB_TITLE: "Summary Overview",
};

export const CALLBACK_REQUESTED = {
  PAGE_TITLE: "Callback Requested",
  PAGE_SUB_TITLE: "Summary Overview",
};

export const NEWEST_OLDEST_SORT = {
  OLDEST_TO_NEWEST: "OldestToNewest",
  NEWEST_TO_OLDEST: "NewestToOldest",
};

export const COMPLETED_CLAIMS = {
  PAGE_TITLE: "Completed Claims",
  PAGE_SUB_TITLE: "Summary Overview",
};

export const OFFER_ACTION_REQUIRED = {
  PAGE_TITLE: "Offer Action Required",
  PAGE_SUB_TITLE: "Summary Overview",
};

export const optionValues_NewClaims = [
  { value: "S", optionName: "Action Required", count: 1 },
  { value: "D", optionName: "Declined", count: 1 },
  { value: "I", optionName: "Information Requested", count: 1 },
  { value: "A", optionName: "All Claims", count: 1 },
];

export const optionValues_InProgress = [
  { value: "S", optionName: "Action Required", count: 1 },
  { value: "D", optionName: "Declined", count: 1 },
  { value: "I", optionName: "Information Requested", count: 1 },
  { value: "V", optionName: "Vendor Offer Made", count: 1 },
  { value: "A", optionName: "All Claims", count: 1 },
];
