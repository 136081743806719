import React from "react";
import PropTypes from "prop-types";
import COLOURS from "../../../theme/base/colours";

const Palette = ({ width, height, fillColour }) => (
  <svg
    xmlns="
http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fillColour}
    viewBox="0 0 16 16"
  >
    <path d="M8 5a1.5 1.5 0 100-3 1.5 1.5 0 000 3m4 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3M5.5 7a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0m.5 6a1.5 1.5 0 100-3 1.5 1.5 0 000 3"></path>
    <path d="M16 8c0 3.15-1.866 2.585-3.567 2.07C11.42 9.763 10.465 9.473 10 10c-.603.683-.475 1.819-.351 2.92C9.826 14.495 9.996 16 8 16a8 8 0 118-8m-8 7c.611 0 .654-.171.655-.176.078-.146.124-.464.07-1.119-.014-.168-.037-.37-.061-.591-.052-.464-.112-1.005-.118-1.462-.01-.707.083-1.61.704-2.314.369-.417.845-.578 1.272-.618.404-.038.812.026 1.16.104.343.077.702.186 1.025.284l.028.008c.346.105.658.199.953.266.653.148.904.083.991.024C14.717 9.38 15 9.161 15 8a7 7 0 10-7 7"></path>
  </svg>
);

Palette.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

Palette.defaultProps = {
  width: "16",
  height: "16",
  fill: COLOURS.PRIMARY.base,
};

export default Palette;
