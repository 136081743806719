import { FetchVendorPermissions } from "../../api/VendorInformation";

export const FETCH_VENDOR_PERMISSIONS = 
  "@vendorInformation/FETCH_VENDOR_PERMISSIONS";
export const FETCH_VENDOR_PERMISSIONS_SUCCESS = 
  "@vendorInformation/FETCH_VENDOR_PERMISSIONS_SUCCESS";
export const FETCH_VENDOR_PERMISSIONS_FAILURE = 
  "@vendorInformation/FETCH_VENDOR_PERMISSIONS_FAILURE";

export const fetchVendorPermissions = () => (dispatch, getState) => {
    dispatch({
        type: FETCH_VENDOR_PERMISSIONS,
    });
    const vendorInfoInstance = new FetchVendorPermissions({
        credentials: getState().authentication.credentials,
    });

    return vendorInfoInstance.call().then(
        (response) => {
            return dispatch(fetchVendorPermissionsSuccess(response));
        },
    (err) => {
        return dispatch(fetchVendorPermissionsFailure(err));
        },
    );
}

const fetchVendorPermissionsSuccess = (data) => {
    return {
        type: FETCH_VENDOR_PERMISSIONS_SUCCESS,
        payload: data,
    };
};

const fetchVendorPermissionsFailure = (error) => {
    return {
        type: FETCH_VENDOR_PERMISSIONS_FAILURE,
        payload: error,
    };
};

const initialState = {
    isFetchingVendorPermissions: false,
    vendorPermissions: {},
  };

export const reducer = (state = initialState, action) => {
 switch (action.type) {
    case FETCH_VENDOR_PERMISSIONS:
        return {
            ...state,
            isFetchingVendorPermissions: true,
        };
    case FETCH_VENDOR_PERMISSIONS_SUCCESS:
        return {
            ...state,
            isFetchingVendorPermissions: false,
            vendorPermissions: action.payload,
        };
    case FETCH_VENDOR_PERMISSIONS_FAILURE:
        return {
            ...state,
            isFetchingVendorPermissions: false,
        };
    default:
        return state;
 }
};

export default reducer;