import {
  FetchVehicleRecordPrimaryDetails,
  FetchVehicleRecordMoreInfoDetails,
  FetchVehicleRecordSalesInfo,
  FetchVehicleRecordEventHistory,
  FetchVehicleRecordProfiles,
  FetchVehicleRecordPurchaseInfo,
  FetchVehicleRecordBiddingInfo,
  FetchVehicleRecordInspections,
  FetchVehicleLocation,
  DeclineBid,
  AcceptBid,
  AddEventHistory,
  FetchAdditionalEquipment,
  UpdateAdditionalEquipment,
} from "../../api/VehicleDetailsVendor";

import { fetchAuctionSalesForVehicle } from "./auction";
import { fetchVendorPermissions } from "./vendorInformation";
import { openModal } from "./global";
import {
  VENDOR_VEHICLE_LOCATION_MAP,
  VENDOR_VEHICLE_NAVIGATION_OPTIONS,
} from "../../shared/utility";
import {
  getStandardEquipment,
  getTechnicalSpecification,
  fetchServiceHistory,
  fetchDocuments,
} from "./vehicle";
import { closeModal } from "./global";

export const FETCH_VEHICLE_PRIMARY_DETAILS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_PRIMARY_DETAILS";
export const FETCH_VEHICLE_PRIMARY_DETAILS_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_PRIMARY_DETAILS_SUCCESS";
export const FETCH_VEHICLE_PRIMARY_DETAILS_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_PRIMARY_DETAILS_ERROR";
export const FETCH_VEHICLE_MORE_INFO_DETAILS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_MORE_INFO_DETAILS";
export const FETCH_VEHICLE_MORE_INFO_DETAILS_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_MORE_INFO_DETAILS_SUCCESS";
export const FETCH_VEHICLE_MORE_INFO_DETAILS_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_MORE_INFO_DETAILS_ERROR";
export const FETCH_VEHICLE_SALES_INFO_DETAILS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_SALES_INFO_DETAILS";
export const FETCH_VEHICLE_SALES_INFO_DETAILS_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_SALES_INFO_DETAILS_SUCCESS";
export const FETCH_VEHICLE_SALES_INFO_DETAILS_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_SALES_INFO_DETAILS_ERROR";
export const FETCH_VEHICLE_EVENT_HISTORY =
  "@vehicleDetailsVendor/FETCH_VEHICLE_EVENT_HISTORY";
export const FETCH_VEHICLE_EVENT_HISTORY_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_EVENT_HISTORY_SUCCESS";
export const FETCH_VEHICLE_EVENT_HISTORY_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_EVENT_HISTORY_ERROR";

export const FETCH_VEHICLE_RECORD_PROFILES =
  "@vehicleDetailsVendor/FETCH_VEHICLE_RECORD_PROFILES";
export const FETCH_VEHICLE_RECORD_PROFILES_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_RECORD_PROFILES_SUCCESS";
export const FETCH_VEHICLE_RECORD_PROFILES_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_RECORD_PROFILES_ERROR";
export const FETCH_VEHICLE_PURCHASE_INFO_DETAILS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_PURCHASE_INFO_DETAILS";
export const FETCH_VEHICLE_PURCHASE_INFO_DETAILS_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_PURCHASE_INFO_DETAILS_SUCCESS";
export const FETCH_VEHICLE_PURCHASE_INFO_DETAILS_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_PURCHASE_INFO_DETAILS_ERROR";
export const FETCH_VEHICLE_BIDDING_INFO_DETAILS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_BIDDING_INFO_DETAILS";
export const FETCH_VEHICLE_BIDDING_INFO_DETAILS_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_BIDDING_INFO_DETAILS_SUCCESS";
export const FETCH_VEHICLE_BIDDING_INFO_DETAILS_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_BIDDING_INFO_DETAILS_ERROR";
export const FETCH_VEHICLE_INSPECTION_DETAILS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_INSPECTION_DETAILS";
export const FETCH_VEHICLE_INSPECTION_DETAILS_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_INSPECTION_DETAILS_SUCCESS";
export const FETCH_VEHICLE_INSPECTION_DETAILS_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_INSPECTION_DETAILS_ERROR";
export const SET_ACTIVE_NAVIGATION_OPTION =
  "@vehicleDetailsVendor/SET_ACTIVE_NAVIGATION_OPTION";
export const CLEAR_ACTIVE_NAVIGATION_OPTION =
  "@vehicleDetailsVendor/CLEAR_ACTIVE_NAVIGATION_OPTION";
export const SET_ACTIVE_INSPECTION_ID =
  "@vehicleDetailsVendor/SET_ACTIVE_INSPECTION_ID";
export const CLEAR_ACTIVE_INSPECTION_ID =
  "@vehicleDetailsVendor/CLEAR_ACTIVE_INSPECTION_ID";
export const FETCH_VEHICLE_LOCATION =
  "@vehicleDetailsVendor/FETCH_VEHICLE_LOCATION";
export const FETCH_VEHICLE_LOCATION_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_LOCATION_SUCCESS";
export const FETCH_VEHICLE_LOCATION_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_LOCATION_ERROR";
export const DECLINE_BID = "@vehicleDetailsVendor/DECLINE_BID";
export const DECLINE_BID_SUCCESS = "@vehicleDetailsVendor/DECLINE_BID_SUCCESS";
export const DECLINE_BID_ERROR = "@vehicleDetailsVendor/DECLINE_BID_ERROR";
export const ACCEPT_BID = "@vehicleDetailsVendor/ACCEPT_BID";
export const ACCEPT_BID_SUCCESS = "@vehicleDetailsVendor/ACCEPT_BID_SUCCESS";
export const ACCEPT_BID_ERROR = "@vehicleDetailsVendor/ACCEPT_BID_ERROR";
export const SET_ACTIVE_CONFIRM_MODAL_OPTIONS =
  "@vehicleDetailsVendor/SET_ACTIVE_CONFIRM_MODAL_OPTIONS";
export const CLEAR_ACTIVE_CONFIRM_MODAL_OPTIONS =
  "@vehicleDetailsVendor/CLEAR_ACTIVE_CONFIRM_MODAL_OPTIONS";
export const SET_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS =
  "@vehicleDetailsVendor/SET_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS";
export const CLEAR_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS =
  "@vehicleDetailsVendor/CLEAR_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS";
export const ADD_EVENT_HISTORY = "@vehicleDetailsVendor/ADD_EVENT_HISTORY";
export const ADD_EVENT_HISTORY_SUCCESS =
  "@vehicleDetailsVendor/ADD_EVENT_HISTORY_SUCCESS";
export const ADD_EVENT_HISTORY_ERROR =
  "@vehicleDetailsVendor/ADD_EVENT_HISTORY_ERROR";
export const SET_ACTIVE_VEHICLE_REFERENCE =
  "@vehicleDetailsVendor/SET_ACTIVE_VEHICLE_REFERENCE";
export const FETCH_ADDITIONAL_EQUIPMENT =
  "@vehicleDetailsVendor/FETCH_ADDITIONAL_EQUIPMENT";
export const FETCH_ADDITIONAL_EQUIPMENT_SUCCESS =
  "@vehicleDetailsVendor/FETCH_ADDITIONAL_EQUIPMENT_SUCCESS";
export const FETCH_ADDITIONAL_EQUIPMENT_ERROR =
  "@vehicleDetailsVendor/FETCH_ADDITIONAL_EQUIPMENT_ERROR";
export const UPDATE_ADDITIONAL_EQUIPMENT =
  "@vehicleDetailsVendor/UPDATE_ADDITIONAL_EQUIPMENT";
export const UPDATE_ADDITIONAL_EQUIPMENT_SUCCESS =
  "@vehicleDetailsVendor/UPDATE_ADDITIONAL_EQUIPMENT_SUCCESS";
export const UPDATE_ADDITIONAL_EQUIPMENT_ERROR =
  "@vehicleDetailsVendor/UPDATE_ADDITIONAL_EQUIPMENT_ERROR";

export const fetchVehiclePrimaryDetails =
  (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_PRIMARY_DETAILS });

    const vehicleDetailsInstance = new FetchVehicleRecordPrimaryDetails({
      params: {
        vehicleId: vehicleId,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        if (
          response.titleInfo.regNo === null ||
          response.titleInfo.regNo === ""
        ) {
          dispatch(openModal("generic-error"));
        } else {
          dispatch(fetchVehiclePrimaryDetailsSuccess(response));
        }
      },
      (err) => {
        dispatch(fetchVehiclePrimaryDetailsError(err));
        dispatch(openModal("generic-error"));
      }
    );
  };

const fetchVehiclePrimaryDetailsSuccess = (data) => async (dispatch) => {
  await dispatch(fetchVendorPermissions());

  await dispatch({
    type: FETCH_VEHICLE_PRIMARY_DETAILS_SUCCESS,
    payload: data,
  });
};

const fetchVehiclePrimaryDetailsError = (error) => {
  return {
    type: FETCH_VEHICLE_PRIMARY_DETAILS_ERROR,
    payload: error,
  };
};

export const fetchVehicleMoreInfoDetails =
  (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_MORE_INFO_DETAILS });

    const vehicleDetailsInstance = new FetchVehicleRecordMoreInfoDetails({
      params: {
        vehicleId: vehicleId,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(fetchVehicleMoreInfoDetailsSuccess(response));
      },
      (err) => {
        dispatch(fetchVehicleMoreInfoDetailsError(err));
      }
    );
  };

const fetchVehicleMoreInfoDetailsSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_MORE_INFO_DETAILS_SUCCESS,
    payload: data,
  };
};

const fetchVehicleMoreInfoDetailsError = (error) => {
  return {
    type: FETCH_VEHICLE_MORE_INFO_DETAILS_ERROR,
    payload: error,
  };
};

export const fetchVehicleSalesInfo = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_VEHICLE_SALES_INFO_DETAILS });

  const vehicleDetailsInstance = new FetchVehicleRecordSalesInfo({
    params: {
      vehicleId: vehicleId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(fetchVehicleSalesInfoSuccess(response));
    },
    (err) => {
      dispatch(fetchVehicleSalesInfoError(err));
    }
  );
};

const fetchVehicleSalesInfoSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_SALES_INFO_DETAILS_SUCCESS,
    payload: data,
  };
};

const fetchVehicleSalesInfoError = (error) => {
  return {
    type: FETCH_VEHICLE_SALES_INFO_DETAILS_ERROR,
    payload: error,
  };
};

export const fetchVehicleEventHistory = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_VEHICLE_EVENT_HISTORY });

  const vehicleDetailsInstance = new FetchVehicleRecordEventHistory({
    params: {
      vehicleId: vehicleId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(fetchVehicleEventHistorySuccess(response));
    },
    (err) => {
      dispatch(fetchVehicleEventHistoryError(err));
    }
  );
};

const fetchVehicleEventHistorySuccess = (data) => {
  return {
    type: FETCH_VEHICLE_EVENT_HISTORY_SUCCESS,
    payload: data,
  };
};

const fetchVehicleEventHistoryError = (error) => {
  return {
    type: FETCH_VEHICLE_EVENT_HISTORY_ERROR,
    payload: error,
  };
};

export const fetchVehiclePurchaseInfo = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_VEHICLE_PURCHASE_INFO_DETAILS });

  const vehicleDetailsInstance = new FetchVehicleRecordPurchaseInfo({
    params: {
      vehicleId: vehicleId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(fetchVehiclePurchaseInfoSuccess(response));
    },
    (err) => {
      dispatch(fetchVehiclePurchaseInfoError(err));
    }
  );
};

const fetchVehiclePurchaseInfoSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_PURCHASE_INFO_DETAILS_SUCCESS,
    payload: data,
  };
};

const fetchVehiclePurchaseInfoError = (error) => {
  return {
    type: FETCH_VEHICLE_PURCHASE_INFO_DETAILS_ERROR,
    payload: error,
  };
};

export const fetchVehicleRecordProfiles =
  (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_RECORD_PROFILES });

    const vehicleDetailsInstance = new FetchVehicleRecordProfiles({
      params: {
        vehicleId: vehicleId,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(fetchVehicleRecordProfilesSuccess(response));
      },
      (err) => {
        dispatch(fetchVehicleRecordProfilesError(err));
      }
    );
  };

const fetchVehicleRecordProfilesSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_RECORD_PROFILES_SUCCESS,
    payload: data,
  };
};

const fetchVehicleRecordProfilesError = (error) => {
  return {
    type: FETCH_VEHICLE_RECORD_PROFILES_ERROR,
    payload: error,
  };
};


export const fetchVehicleBiddingInfo =
  (vehicleId, isAllBids = false) =>
  (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_BIDDING_INFO_DETAILS });

    const vehicleDetailsInstance = new FetchVehicleRecordBiddingInfo({
      params: {
        vehicleId: vehicleId,
        isAllBids: isAllBids,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(fetchVehicleBiddingInfoSuccess(response));
      },
      (err) => {
        dispatch(fetchVehicleBiddingInfoError(err));
      }
    );
  };

const fetchVehicleBiddingInfoSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_BIDDING_INFO_DETAILS_SUCCESS,
    payload: data,
  };
};

const fetchVehicleBiddingInfoError = (error) => {
  return {
    type: FETCH_VEHICLE_BIDDING_INFO_DETAILS_ERROR,
    payload: error,
  };
};

export const fetchVehicleInspections = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_VEHICLE_INSPECTION_DETAILS });

  const vehicleDetailsInstance = new FetchVehicleRecordInspections({
    params: {
      vehicleId: vehicleId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(fetchVehicleInspectionsSuccess(response));
    },
    (err) => {
      dispatch(fetchVehicleInspectionsError(err));
    }
  );
};

const fetchVehicleInspectionsSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_INSPECTION_DETAILS_SUCCESS,
    payload: data,
  };
};

const fetchVehicleInspectionsError = (error) => {
  return {
    type: FETCH_VEHICLE_INSPECTION_DETAILS_ERROR,
    payload: error,
  };
};

export const setActiveNavigationOption = (activeNavOption) => {
  return {
    type: SET_ACTIVE_NAVIGATION_OPTION,
    payload: activeNavOption,
  };
};

export const setActiveVehicleReference = (vehicleReference) => {
  return {
    type: SET_ACTIVE_VEHICLE_REFERENCE,
    payload: vehicleReference,
  };
};

export const clearActiveNavigationOption = () => {
  return {
    type: CLEAR_ACTIVE_NAVIGATION_OPTION,
  };
};

export const setActiveInspectionId = (activeInspectionId) => {
  return {
    type: SET_ACTIVE_INSPECTION_ID,
    payload: activeInspectionId,
  };
};

export const clearActiveInspectionId = () => {
  return {
    type: CLEAR_ACTIVE_INSPECTION_ID,
  };
};

export const fetchVehicleLocation =
  (vehicleId, locationType) => (dispatch, getState) => {
    const locationTypeKey = Object.keys(VENDOR_VEHICLE_LOCATION_MAP).find(
      (key) => VENDOR_VEHICLE_LOCATION_MAP[key] === locationType
    );

    const formattedLocationTypeKey = locationTypeKey.toLowerCase();

    dispatch({
      type: FETCH_VEHICLE_LOCATION,
      payload: { locationType: formattedLocationTypeKey },
    });

    const vehicleDetailsInstance = new FetchVehicleLocation({
      params: {
        vehicleId: vehicleId,
        locationType: locationType,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(
          fetchVehicleLocationSuccess(formattedLocationTypeKey, response)
        );
      },
      (err) => {
        dispatch(fetchVehicleLocationError(formattedLocationTypeKey, err));
      }
    );
  };

export const fetchVehicleLocationSuccess = (locationType, data) => {
  return {
    type: FETCH_VEHICLE_LOCATION_SUCCESS,
    payload: {
      locationType,
      data,
    },
  };
};

export const fetchVehicleLocationError = (locationType, error) => {
  return {
    type: FETCH_VEHICLE_LOCATION_ERROR,
    payload: {
      locationType,
      error,
    },
  };
};

export const acceptBid = (vehicleId, bidId) => (dispatch, getState) => {
  dispatch({ type: ACCEPT_BID });

  const vehicleDetailsInstance = new AcceptBid({
    params: {
      vehicleId: vehicleId,
      bidId: bidId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(acceptBidSuccess(bidId));
      dispatch(openModal("vendor-vehicle-record-transition-modal"));
      setTimeout(() => {
        dispatch(closeModal());
        dispatch(clearActiveConfirmModalOptions());
      }, 1000);
    },
    (err) => {
      dispatch(acceptBidError(err));
      dispatch(clearActiveConfirmModalOptions());
      dispatch(openModal("generic-error"));
    }
  );
};

const acceptBidSuccess = (bidId) => {
  return {
    type: ACCEPT_BID_SUCCESS,
    payload: {
      bidId: bidId,
    },
  };
};

const acceptBidError = (error) => {
  return {
    type: ACCEPT_BID_ERROR,
    payload: error,
  };
};

export const declineBid = (vehicleId, bidId) => (dispatch, getState) => {
  dispatch({ type: DECLINE_BID });

  const vehicleDetailsInstance = new DeclineBid({
    params: {
      vehicleId: vehicleId,
      bidId: bidId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(declineBidSuccess(bidId));
      dispatch(openModal("vendor-vehicle-record-transition-modal"));
      setTimeout(() => {
        dispatch(closeModal());
        dispatch(clearActiveConfirmModalOptions());
      }, 1000);
    },
    (err) => {
      dispatch(declineBidError(err));
      dispatch(clearActiveConfirmModalOptions());
      dispatch(openModal("generic-error"));
    }
  );
};

const declineBidSuccess = (bidId) => {
  return {
    type: DECLINE_BID_SUCCESS,
    payload: {
      bidId: bidId,
    },
  };
};

const declineBidError = (error) => {
  return {
    type: DECLINE_BID_ERROR,
    payload: error,
  };
};

export const setActiveConfirmModalOptions = (type, data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_CONFIRM_MODAL_OPTIONS,
    payload: { type, data },
  });
};

export const clearActiveConfirmModalOptions = () => (dispatch) => {
  dispatch({
    type: CLEAR_ACTIVE_CONFIRM_MODAL_OPTIONS,
  });
};

export const setActiveGenericTextAreaModalOptions =
  (type, data) => (dispatch) => {
    dispatch({
      type: SET_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS,
      payload: { type, data },
    });
  };

export const clearActiveGenericTextAreaModalOptions = () => (dispatch) => {
  dispatch({
    type: CLEAR_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS,
  });
};

export const updateAdditionalEquipment =
  (vehicleId, equipmentText) => (dispatch, getState) => {
    dispatch({ type: UPDATE_ADDITIONAL_EQUIPMENT });
    const vehicleDetailsInstance = new UpdateAdditionalEquipment({
      params: {
        vehicleId: vehicleId,
        equipmentText : equipmentText,
      },
      credentials: getState().authentication.credentials,
    });
    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(updateAdditionalEquipmentSuccess(equipmentText));
      },
      (err) => {
        dispatch(updateAdditionalEquipmentError(err));
        dispatch(openModal("generic-error"));
      }
    );
  };
const updateAdditionalEquipmentSuccess = (data) => {
  return {
    type: UPDATE_ADDITIONAL_EQUIPMENT_SUCCESS,
    payload: data,
  };
};
const updateAdditionalEquipmentError = (error) => {
  return {
    type: UPDATE_ADDITIONAL_EQUIPMENT_ERROR,
    payload: error,
  };
};

export const genericFetchEventForNavigation =
  (activeNavOption, vehicleId) => (dispatch, getState) => {
    switch (activeNavOption) {
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.SALES_INFO:
        return dispatch(fetchVehicleSalesInfo(vehicleId));
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.PURCHASE_INFO:
        return dispatch(fetchVehiclePurchaseInfo(vehicleId));
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.EVENT_HISTORY:
        return dispatch(fetchVehicleEventHistory(vehicleId));
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.PROFILES:
        return dispatch(fetchVehicleRecordProfiles(vehicleId));
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.STANDARD_EQUIPMENT:
        const hasStandardEquipment =
          !!getState().vehicle.standardEquipmentData.standardEquipment;
        if (!hasStandardEquipment) {
          return dispatch(getStandardEquipment(vehicleId));
        }
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.TECHNICAL_SPECIFICATION:
        const hasTechnicalSpecification =
          !!getState().vehicle.technicalSpecificationData.data;
        if (!hasTechnicalSpecification) {
          return dispatch(getTechnicalSpecification(vehicleId));
        }
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.MAINTENANCE_HISTORY:
        const hasServiceHistory =
          !!getState().vehicle.serviceHistoryData.serviceHistory;
        if (!hasServiceHistory) {
          return dispatch(fetchServiceHistory(vehicleId));
        }
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.AUCTION_SALES:
        return dispatch(fetchAuctionSalesForVehicle(vehicleId));
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.BIDDING_INFO:
        return dispatch(fetchVehicleBiddingInfo(vehicleId));
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.INSPECTION_REPORT:
        return dispatch(fetchVehicleInspections(vehicleId));
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.DOCUMENTATION:
        return dispatch(fetchDocuments(vehicleId));
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.VEHICLE_LOCATION:
        return dispatch(
          fetchVehicleLocation(vehicleId, VENDOR_VEHICLE_LOCATION_MAP.HISTORY)
        );
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.DRIVER_CUSTOMER:
        return dispatch(
          fetchVehicleLocation(
            vehicleId,
            VENDOR_VEHICLE_LOCATION_MAP.DRIVER_AND_CUSTOMER_LOCATION
          )
        );
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.ADDITIONAL_EQUIPMENT:
        return dispatch(fetchAdditionalEquipment(vehicleId));
      default:
        return dispatch(fetchVehicleSalesInfo(vehicleId));
    }
  };

export const addEventHistory =
  (vehicleId, eventDescription) => (dispatch, getState) => {
    dispatch({ type: ADD_EVENT_HISTORY });

    const vehicleDetailsInstance = new AddEventHistory({
      params: {
        vehicleId: vehicleId,
        eventDescription: eventDescription,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(addEventHistorySuccess(response));
        dispatch(fetchVehicleEventHistory(vehicleId));
        dispatch(closeModal());
      },
      (err) => {
        dispatch(addEventHistoryError(err));
        dispatch(openModal("generic-error"));
      }
    );
  };

export const addEventHistorySuccess = (data) => {
  return {
    type: ADD_EVENT_HISTORY_SUCCESS,
    payload: {
      data,
    },
  };
};

export const addEventHistoryError = (error) => {
  return {
    type: ADD_EVENT_HISTORY_ERROR,
    payload: {
      error,
    },
  };
};

export const fetchAdditionalEquipment = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_ADDITIONAL_EQUIPMENT });

  const vehicleDetailsInstance = new FetchAdditionalEquipment({
    params: {
      vehicleId: vehicleId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(fetchAdditionalEquipmentSuccess(response));
    },
    (err) => {
      dispatch(fetchAdditionalEquipmentError(err));
    }
  );
};

const fetchAdditionalEquipmentSuccess = (data) => {
  return {
    type: FETCH_ADDITIONAL_EQUIPMENT_SUCCESS,
    payload: data,
  };
};

const fetchAdditionalEquipmentError = (error) => {
  return {
    type: FETCH_ADDITIONAL_EQUIPMENT_ERROR,
    payload: error,
  };
};

const initialState = {
  primaryDetails: {
    data: null,
    isFetching: false,
    error: null,
  },
  moreInfoDetails: {
    data: null,
    isFetching: false,
    error: null,
  },
  salesInformation: {
    data: null,
    isFetching: false,
    error: null,
  },
  eventHistory: {
    data: null,
    isFetching: false,
    error: null,
  },
  profileSummary: {
    profiles: null,
    daysOnSale: null,
    buyNowPrice: null,
    isFetching: false,
    error: null,
  },
  purchaseInformation: {
    data: null,
    isFetching: false,
    error: null,
  },
  inspections: {
    activeInspectionId: null,
    data: null,
    isFetching: false,
    error: null,
  },
  vehicleLocation: {
    data: {
      history: null,
      current_location: null,
      driver_location: null,
      customer_location: null,
      defleet_location: null,
      driver_and_customer_location: null,
    },
    isFetching: {
      history: false,
      current_location: false,
      driver_location: false,
      customer_location: false,
      defleet_location: false,
      driver_and_customer_location: false,
    },
    error: null,
  },
  biddingInformation: {
    bids: null,
    acceptedBid: null,
    salesInformation: null,
    showPriceWhenBidding: null,
    showReserveWhenBidding: null,
    allowBidsBelowReserve: null,
    allowBuyItNow: null,
    isFetching: false,
    isDeclineBid: false,
    isAcceptBid: false,
    error: null,
  },
  activeNavOption: null,
  activeVehicleReference: null,
  activeConfirmModalOptions: {
    type: null,
    data: null,
  },
  activeGenericTextAreaModalOptions: {
    type: null,
    data: null,
  },
  additionalEquipment: {
    data: null, 
    isFetching: false,
    isUpdating:false,
    error: null,
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VEHICLE_PRIMARY_DETAILS:
      return {
        ...state,
        primaryDetails: {
          ...state.primaryDetails,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_PRIMARY_DETAILS_SUCCESS:
      return {
        ...state,
        primaryDetails: {
          ...state.primaryDetails,
          data: action.payload,
          isFetching: false,
        },
        inspections: {
          ...state.inspections,
          activeInspectionId:
            action.payload?.gradeOtherInfo?.mostRecentInspectionId,
        },
      };
    case FETCH_VEHICLE_PRIMARY_DETAILS_ERROR:
      return {
        ...state,
        primaryDetails: {
          ...state.primaryDetails,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_MORE_INFO_DETAILS:
      return {
        ...state,
        moreInfoDetails: {
          ...state.moreInfoDetails,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_MORE_INFO_DETAILS_SUCCESS:
      return {
        ...state,
        moreInfoDetails: {
          ...state.moreInfoDetails,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_MORE_INFO_DETAILS_ERROR:
      return {
        ...state,
        moreInfoDetails: {
          ...state.moreInfoDetails,
          error: action.payload,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_SALES_INFO_DETAILS:
      return {
        ...state,
        salesInformation: {
          ...state.salesInformation,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_SALES_INFO_DETAILS_SUCCESS:
      return {
        ...state,
        salesInformation: {
          ...state.salesInformation,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_SALES_INFO_DETAILS_ERROR:
      return {
        ...state,
        salesInformation: {
          ...state.salesInformation,
          isFetching: false,
          error: action.payload,
        },
      };

    case FETCH_VEHICLE_EVENT_HISTORY:
      return {
        ...state,
        eventHistory: {
          ...state.eventHistory,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_EVENT_HISTORY_SUCCESS:
      return {
        ...state,
        eventHistory: {
          ...state.eventHistory,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_EVENT_HISTORY_ERROR:
      return {
        ...state,
        eventHistory: {
          ...state.eventHistory,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_RECORD_PROFILES:
      return {
        ...state,
        profileSummary: {
          ...state.profileSummary,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_RECORD_PROFILES_SUCCESS:
      return {
        ...state,
        profileSummary: {
          ...state.profileSummary,
          profiles: action.payload?.profiles,
          daysOnSale: action.payload?.daysOnSale,
          buyNowPrice: action.payload?.buyNowPrice,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_RECORD_PROFILES_ERROR:
      return {
        ...state,
        profileSummary: {
          ...state.profileSummary,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_PURCHASE_INFO_DETAILS:
      return {
        ...state,
        purchaseInformation: {
          ...state.purchaseInformation,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_PURCHASE_INFO_DETAILS_SUCCESS:
      return {
        ...state,
        purchaseInformation: {
          ...state.purchaseInformation,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_PURCHASE_INFO_DETAILS_ERROR:
      return {
        ...state,
        purchaseInformation: {
          ...state.purchaseInformation,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_BIDDING_INFO_DETAILS:
      return {
        ...state,
        biddingInformation: {
          ...state.biddingInformation,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_BIDDING_INFO_DETAILS_SUCCESS:
      return {
        ...state,
        biddingInformation: {
          ...state.biddingInformation,
          bids: action.payload?.bids,
          acceptedBid: action.payload?.acceptedBid,
          salesInformation: action.payload?.salesInfo,
          showPriceWhenBidding: action.payload?.showPriceWhenBidding,
          showReserveWhenBidding: action.payload?.showReserveWhenBidding,
          allowBidsBelowReserve: action.payload?.allowBidsBelowReserve,
          allowBuyItNow: action.payload?.allowBuyItNow,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_BIDDING_INFO_DETAILS_ERROR:
      return {
        ...state,
        biddingInformation: {
          ...state.biddingInformation,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_INSPECTION_DETAILS:
      return {
        ...state,
        inspections: {
          ...state.inspections,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_INSPECTION_DETAILS_SUCCESS:
      return {
        ...state,
        inspections: {
          ...state.inspections,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_INSPECTION_DETAILS_ERROR:
      return {
        ...state,
        inspections: {
          ...state.inspections,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_LOCATION:
      return {
        ...state,
        vehicleLocation: {
          ...state.vehicleLocation,
          isFetching: {
            ...state.vehicleLocation.isFetching,
            [action.payload.locationType]: true,
          },
        },
      };
    case FETCH_VEHICLE_LOCATION_SUCCESS:
      return {
        ...state,
        vehicleLocation: {
          ...state.vehicleLocation,
          data: {
            ...state.vehicleLocation.data,
            [action.payload.locationType]: action.payload.data,
          },
          isFetching: {
            ...state.vehicleLocation.isFetching,
            [action.payload.locationType]: false,
          },
        },
      };
    case FETCH_VEHICLE_LOCATION_ERROR:
      return {
        ...state,
        vehicleLocation: {
          ...state.vehicleLocation,
          isFetching: {
            ...state.vehicleLocation.isFetching,
            [action.payload.locationType]: false,
          },
          error: action.payload,
        },
      };
    case ACCEPT_BID:
      return {
        ...state,
        biddingInformation: {
          ...state.biddingInformation,
          isAcceptBid: true,
        },
      };
    case ACCEPT_BID_SUCCESS:
      return {
        ...state,
        biddingInformation: {
          ...state.biddingInformation,
          isAcceptBid: false,
          bids: state.biddingInformation.bids.map((bid) => ({
            ...bid,
            bidStatus:
              bid.bidId === action.payload.bidId ? "Accepted" : bid.bidStatus,
          })),
        },
      };
    case ACCEPT_BID_ERROR:
      return {
        ...state,
        biddingInformation: {
          ...state.biddingInformation,
          isAcceptBid: false,
          error: action.payload,
        },
      };
    case DECLINE_BID:
      return {
        ...state,
        biddingInformation: {
          ...state.biddingInformation,
          isDeclineBid: true,
        },
      };
    case DECLINE_BID_SUCCESS:
      return {
        ...state,
        biddingInformation: {
          ...state.biddingInformation,
          isDeclineBid: false,
          bids: state.biddingInformation.bids.map((bid) => ({
            ...bid,
            bidStatus:
              bid.bidId === action.payload.bidId ? "Declined" : bid.bidStatus,
          })),
        },
      };
    case DECLINE_BID_ERROR:
      return {
        ...state,
        biddingInformation: {
          ...state.biddingInformation,
          isDeclineBid: false,
          error: action.payload,
        },
      };
    case SET_ACTIVE_NAVIGATION_OPTION:
      return {
        ...state,
        activeNavOption: action.payload,
      };
    case SET_ACTIVE_VEHICLE_REFERENCE:
      return {
        ...state,
        activeVehicleReference: action.payload,
      };
    case SET_ACTIVE_INSPECTION_ID:
      return {
        ...state,
        inspections: {
          ...state.inspections,
          activeInspectionId: action.payload,
        },
      };
    case SET_ACTIVE_CONFIRM_MODAL_OPTIONS:
      return {
        ...state,
        activeConfirmModalOptions: {
          ...state.activeConfirmModalOptions,
          type: action.payload.type,
          data: action.payload.data,
        },
      };
    case CLEAR_ACTIVE_CONFIRM_MODAL_OPTIONS:
      return {
        ...state,
        activeConfirmModalOptions: initialState.activeConfirmModalOptions,
      };

    case SET_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS:
      return {
        ...state,
        activeGenericTextAreaModalOptions: {
          ...state.activeGenericTextAreaModalOptions,
          type: action.payload.type,
          data: action.payload.data,
        },
      };
    case CLEAR_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS:
      return {
        ...state,
        activeGenericTextAreaModalOptions:
          initialState.activeGenericTextAreaModalOptions,
      };
    case ADD_EVENT_HISTORY_SUCCESS:
      return {
        ...state,
        eventHistory: {
          ...state.eventHistory,
          isSaving: false,
          error: null,
        },
      };
    case ADD_EVENT_HISTORY_ERROR:
      return {
        ...state,
        eventHistory: {
          ...state.eventHistory,
          isSaving: false,
          error: action.payload,
        },
      };
    case ADD_EVENT_HISTORY:
      return {
        ...state,
        eventHistory: {
          ...state.eventHistory,
          isSaving: true,
        },
      };
    case FETCH_ADDITIONAL_EQUIPMENT:
      return {
        ...state,
        additionalEquipment: {
          ...state.additionalEquipment,
          isFetching: true,
        },
      };
    case FETCH_ADDITIONAL_EQUIPMENT_SUCCESS:
      return {
        ...state,
        additionalEquipment: {
          ...state.additionalEquipment,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_ADDITIONAL_EQUIPMENT_ERROR:
      return {
        ...state,
        additionalEquipment: {
          ...state.additionalEquipment,
          isFetching: false,
          error: action.payload,
        },
      };

      case UPDATE_ADDITIONAL_EQUIPMENT:
        return {
          ...state,
          additionalEquipment: {
            ...state.additionalEquipment,
            isUpdating: true,
          },
        };
      case UPDATE_ADDITIONAL_EQUIPMENT_SUCCESS:
        return {
          ...state,
          additionalEquipment: {
            ...state.additionalEquipment,
            data: {
              ...state.additionalEquipment.data,
              equipmentText: action.payload,    
            },
            isUpdating: false,
          },
        };
      case UPDATE_ADDITIONAL_EQUIPMENT_ERROR:
        return {
          ...state,
          additionalEquipment: {
            ...state.additionalEquipment,
            isUpdating: false,
            error: action.payload,
          },
        };
    default:
      return state;
  }
};

export default reducer;
