import styled from "styled-components";
import BidInputError from "../BidInput/child-components/BidInputError";
import ButtonLink from "../ButtonLink";
import colours from "../../theme/base/colours";

export const DragDropItemContent = styled.div`
  max-height: 20rem; 
  overflow-y: auto; 
  overflow-x: hidden; 

  div {
    margin: 0.5rem 0;
    display: flex;
    height: min-content;

    > div {
      margin-right: 1rem;
    }
  }

  .failedItems {
    margin: 0;
    pointer-events: none;
    ${ButtonLink} {
      text-align: left;
      word-break: break-all;
      color: ${colours.RED.base};
    }
    .failedFile {
      width: 45rem;
      margin-right: 1rem;
    }
    ${BidInputError} {
      line-height: 2rem;
      height: 5rem;
      margin-right: 0rem;
    }
  }
`;
