import styled from "styled-components";
import Datepicker from "./Datepicker";

export const StyledDatepicker = styled(Datepicker)`
  input {
    width: 100%;
    height: 5.2rem;
    background: ${(props) => props.theme.COLOURS.WHITE};
    border-radius: 0.5rem;
    border: 0.2rem solid ${(props) => props.theme.COLOURS.SECONDARY.shades[20]};
    padding: 2rem 0 2rem 2rem;
    ${(props) => props.theme.FONTS.Text["paragraphCompact"]};
    color: ${(props) => props.theme.COLOURS.PRIMARY.shades[50]};
    cursor: pointer;
  }

  .rdp-day {
    width: 4.4rem;
    height: 4.4rem;
    ${(props) => props.theme.FONTS.Text.calendarDays};
    color: ${(props) => props.theme.COLOURS.SECONDARY.base};
    opacity: 0.8;
    padding: 0;
    border-bottom: solid ${(props) => props.theme.COLOURS.RED} 1.5rem;
    border-right: solid ${(props) => props.theme.COLOURS.RED} 0.1rem;
    border-radius: 0;
    &:hover {
      background-color: ${(props) =>
    props.theme.COLOURS.PRIMARY.base} !important;
      color: ${(props) => props.theme.COLOURS.WHITE};
      opacity: 1;
    }
    &_selected {
      background-color: ${(props) =>
    props.theme.COLOURS.PRIMARY.base} !important;
      color: ${(props) => props.theme.COLOURS.WHITE};
      opacity: 1;
    }
  }

  .rdp-day_outside {
    background-color: none;
  }

  .input-search {
    position: relative;
    width: 100%;
  }

  .input-search__icon {
    cursor: pointer;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    height: 4.2rem;
    display: flex;
  }

  .DayPickerInput {
    display: flex;
    pointer-events: ${(props) => (props.disabled ? "none" : "")};
  }

  .dayPicker-container {
    align-items: center;
    background: ${(props) => props.theme.COLOURS.TRANSPARENCIES.primaryDark};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    padding: 2rem;
  }
  .rdp {
    background: ${(props) => props.theme.COLOURS.WHITE};
    position: relative;
    padding: 2rem;

    &-wrapper {
      width: 34.3rem;
      min-height: 42rem;
      padding: 0;
    }
    &-caption_label {
      text-align: center;
      margin: 1.7rem 0 2rem;

      > div {
        ${(props) => props.theme.FONTS.Text.calendarTitle};
        color: ${(props) => props.theme.COLOURS.PRIMARY.base};
      }
    }
    &-NavBar {
      width: 100%;
      height: 0;
    }
    &-NavButton {
      background-image: unset;
    }
    &-NavButton--prev {
      left: 1.5rem;
      top: 1.5rem;
      padding: 0.5rem;
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 1.2rem;
        height: 0.2rem;
        background-color: ${(props) =>
    props.theme.COLOURS.SECONDARY.shades[30]};
        opacity: 0.8;
        transform: rotate(35deg);
        top: 11px;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 1.2rem;
        height: 0.2rem;
        background-color: ${(props) =>
    props.theme.COLOURS.SECONDARY.shades[30]};
        opacity: 0.8;
        transform: rotate(-35deg);
      }
    }
    &-NavButton--next {
      right: 1.5rem;
      top: 1.5rem;
      padding: 0.5rem;
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 1.2rem;
        height: 0.2rem;
        background-color: ${(props) =>
    props.theme.COLOURS.SECONDARY.shades[30]};
        opacity: 0.8;
        transform: rotate(-35deg);
        top: 1.1rem;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 1.2rem;
        height: 0.2rem;
        background-color: ${(props) =>
    props.theme.COLOURS.SECONDARY.shades[30]};
        opacity: 0.8;
        transform: rotate(35deg);
      }
    }
    &_month {
      margin: 0 0.9rem 0 1em;
      border-collapse: unset;
    }
    &_weeknumber {
      width: 4.4rem;
      height: 4.4rem;
      vertical-align: middle;
      border-bottom: solid ${(props) => props.theme.COLOURS.WHITE} 1.5rem;
      abbr {
        ${(props) => props.theme.FONTS.Text.calendarDays};
        color: ${(props) => props.theme.COLOURS.SECONDARY.shades[40]};
        text-transform: uppercase;
      }
    }
    &_day {
      width: 4.4rem;
      height: 4.4rem;
      ${(props) => props.theme.FONTS.Text.calendarDays};
      color: ${(props) => props.theme.COLOURS.SECONDARY.base};
      opacity: 0.8;
      padding: 0;
      border-bottom: solid ${(props) => props.theme.COLOURS.RED} 1.5rem;
      border-right: solid ${(props) => props.theme.COLOURS.RED} 0.1rem;
      border-radius: 0;
      &:hover {
        background-color: ${(props) =>
    props.theme.COLOURS.PRIMARY.base} !important;
        color: ${(props) => props.theme.COLOURS.WHITE};
        opacity: 1;
      }
      &_outside {
        color: ${(props) => props.theme.COLOURS.SECONDARY.shades[30]};
        background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[5]};
        border-radius: 0;
      }
      &_today {
        background-color: ${(props) =>
    props.theme.COLOURS.PRIMARY.shades[20]} !important;
      }
      &_selected {
        background-color: ${(props) =>
    props.theme.COLOURS.PRIMARY.base} !important;
        color: ${(props) => props.theme.COLOURS.WHITE};
        opacity: 1;
      }
    }
  }
`;

export default StyledDatepicker;
