import Api from "./Base";
import settings from "../clientappsettings.json";

class VendorInformation extends Api {
  baseUrl = settings.VENDORS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchVendorPermissions extends VendorInformation {
    requestUrl = `/Vendor/Permissions`;
    formatResponseData(response) {
      return response?.data;
    }
}