import {
    FetchAuctionSalesForVehicle,
    FetchAuctionSaleDetails,
  } from "../../api/Auction";

export const FETCH_AUCTION_SALES_FOR_VEHICLE =
  "@auctions/FETCH_AUCTION_SALES_FOR_VEHICLE";
export const FETCH_AUCTION_SALES_FOR_VEHICLE_SUCCESS =
  "@auctions/FETCH_AUCTION_SALES_FOR_VEHICLE_SUCCESS";
export const FETCH_AUCTION_SALES_FOR_VEHICLE_ERROR =
  "@auctions/FETCH_AUCTION_SALES_FOR_VEHICLE_ERROR";
export const FETCH_AUCTION_SALE_DETAILS =
  "@auctions/FETCH_AUCTION_SALE_DETAILS";
export const FETCH_AUCTION_SALE_DETAILS_SUCCESS =
  "@auctions/FETCH_AUCTION_SALE_DETAILS_SUCCESS";
export const FETCH_AUCTION_SALE_DETAILS_ERROR =
  "@auctions/FETCH_AUCTION_SALE_DETAILS_ERROR";
export const CLEAR_AUCTION_SALE_DETAILS = 
  "@auctions/CLEAR_AUCTION_SALE_DETAILS";

export const fetchAuctionSalesForVehicle =
  (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_AUCTION_SALES_FOR_VEHICLE });

    const auctionInstance = new FetchAuctionSalesForVehicle({
      params: {
        vehicleId: vehicleId,
      },
      credentials: getState().authentication.credentials,
    });

    auctionInstance.call().then(
      (response) => {
        dispatch(fetchAuctionSalesForVehicleSuccess(response));
      },
      (err) => {
        dispatch(fetchAuctionSalesForVehicleError(err));
      }
    );
  };

const fetchAuctionSalesForVehicleSuccess = (data) => {
  return {
    type: FETCH_AUCTION_SALES_FOR_VEHICLE_SUCCESS,
    payload: data,
  };
};

const fetchAuctionSalesForVehicleError = (error) => {
  return {
    type: FETCH_AUCTION_SALES_FOR_VEHICLE_ERROR,
    payload: error,
  };
};

export const fetchAuctionSaleDetails =
  (auctionSaleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_AUCTION_SALE_DETAILS });

    const auctionInstance = new FetchAuctionSaleDetails({
      params: {
        auctionSaleId: auctionSaleId,
      },
      credentials: getState().authentication.credentials,
    });

    auctionInstance.call().then(
      (response) => {
        dispatch(fetchAuctionSaleDetailsSuccess(response));
      },
      (err) => {
        dispatch(fetchAuctionSaleDetailsError(err));
      }
    );
  };

const fetchAuctionSaleDetailsSuccess = (data) => {
  return {
    type: FETCH_AUCTION_SALE_DETAILS_SUCCESS,
    payload: data,
  };
};

const fetchAuctionSaleDetailsError = (error) => {
  return {
    type: FETCH_AUCTION_SALE_DETAILS_ERROR,
    payload: error,
  };
};

export const clearAuctionSaleDetails = () => {
  return {
    type: CLEAR_AUCTION_SALE_DETAILS,
  };
};

const initialState = {
    auctionSales: {
      data: null,
      isFetching: false,
      error: null,
    },
    auctionSaleDetails: {
      data: null,
      isFetching: false,
      error: null,
    },
  };

export const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_AUCTION_SALES_FOR_VEHICLE:
        return {
          ...state,
          auctionSales: {
            ...state.auctionSales,
            isFetching: true,
          },
        };
      case FETCH_AUCTION_SALES_FOR_VEHICLE_SUCCESS:
        return {
          ...state,
          auctionSales: {
            ...state.auctionSales,
            data: action.payload,
            isFetching: false,
          },
        };
      case FETCH_AUCTION_SALES_FOR_VEHICLE_ERROR:
        return {
          ...state,
          auctionSales: {
            ...state.auctionSales,
            isFetching: false,
            error: action.payload,
          },
        };
  
      case FETCH_AUCTION_SALE_DETAILS:
        return {
          ...state,
          auctionSaleDetails: {
            ...state.auctionSaleDetails,
            isFetching: true,
          },
        };
      case FETCH_AUCTION_SALE_DETAILS_SUCCESS:
        return {
          ...state,
          auctionSaleDetails: {
            ...state.auctionSaleDetails,
            data: action.payload,
            isFetching: false,
          },
        };
      case FETCH_AUCTION_SALE_DETAILS_ERROR:
        return {
          ...state,
          auctionSaleDetails: {
            ...state.auctionSaleDetails,
            isFetching: false,
            error: action.payload,
          },
        };
      case CLEAR_AUCTION_SALE_DETAILS:
        return {
          ...state,
          auctionSaleDetails: {
            ...initialState.auctionSaleDetails,
          },
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  