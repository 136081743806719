import Api from "./Base";
import settings from "../clientappsettings.json";

class Auction extends Api {
    baseUrl = settings.VENDORS_URL;
    headers = {
      Authorization: `Bearer ${this.getCredentials()}`,
    };
}

export class FetchAuctionSalesForVehicle extends Auction {
    requestUrl = `/Auction/AuctionSales/${this.getParams().vehicleId}`;
    formatResponseData(response) {
        return response?.data;
    }
}

export class FetchAuctionSaleDetails extends Auction {
    requestUrl = `/Auction/AuctionSaleDetails/${this.getParams().auctionSaleId}`;
    headers = {
      Authorization: `Bearer ${this.getCredentials()}`
    };
    formatResponseData(response) {
      return response?.data;
    }
}