import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  EVENTHISTORY_HEADERTEXT,
  EVENTHISTORY_WORDLIMIT,
} from "./const"
import HeaderText from "../HeaderText";
import ParagraphText from "../ParagraphText";
import TextArea from "../TextArea";
import ModalInsert from "../ModalInsert";
import Panel from "../Panel";
import { closeModal } from "../../../state/reducers/global";
import { addEventHistory, clearActiveGenericTextAreaModalOptions } from "../../../state/reducers/vehicleDetailsVendor";

const GenericTextAreaModal = () => {
  const dispatch = useDispatch();

  const { type, data } = useSelector(
    (state) => state.vehicleDetailsVendor.activeGenericTextAreaModalOptions,
  );

  const [textAreaValue, setTextAreaValue] = useState("");

  const disableCheck = textAreaValue.length === 0;

  const getText = (action) => {
    switch (action) {
      case "event-history":
        return {
          headerText: EVENTHISTORY_HEADERTEXT, 
          wordLimit: EVENTHISTORY_WORDLIMIT
        };
      default:
        return {
          headerText: "", 
          bodyText: "",
          wordLimit: ""
        };
    }
  };

  const { headerText, bodyText, wordLimit } = getText(data?.action);

  const onCancel = () => {
    dispatch(closeModal());
    dispatch(clearActiveGenericTextAreaModalOptions())
  };

  const onClickSubmitData = () => {
    if(data.action === "event-history") {
      dispatch(addEventHistory(data.vehicleReference, textAreaValue))
    }
    dispatch(clearActiveGenericTextAreaModalOptions())
  }

  function onChangeInput(e) {
    const { value } = e.target;
    setTextAreaValue(value);
  }

  return (
    <ModalInsert>
      <Panel
        mobileFlex
        style={{ height: "100%", borderRadius: "0.5rem" }}
        actions={[
          {
            content: "Close",
            action: onCancel,
            type: "ghost",
          },
          {
            content: "Submit",
            action: onClickSubmitData,
            type: "primary",
            disabled: disableCheck,
          },
        ]}
      >
        <HeaderText>{headerText}</HeaderText>
        <ParagraphText>{bodyText}</ParagraphText>
        <TextArea
        value={textAreaValue}
        className="text-area__input"
        onChange={onChangeInput}
        validValue={true}
        resize="none"
        maximumCharactersBottom={wordLimit}
        rows="7"
        cols="80"
      ></TextArea>
      </Panel>
  </ModalInsert>
  );
};

export default GenericTextAreaModal;
