import styled, { css } from "styled-components";

import ParagraphText from "./ParagraphText.jsx";
import breakpoints from "../../theme/breakpoints";

export const StyledParagraphText = styled(ParagraphText)`
  color: ${(props) => props.theme.COLOURS.PRIMARY.shades[60]};
  margin-bottom: ${(props) =>
    props.margin ? props.theme.SPACING[props.margin] : 0};
  ${(props) => props.theme.FONTS.Text["paragraph"]};
  font-size: ${(props) => props.theme.FONTS.sizes[30]};

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    ${(props) => props.theme.FONTS.Text["paragraph"]};
  }
    
  ${(props) =>
    props.primary &&
    css`
      color: ${props.theme.COLOURS.PRIMARY.base};
    `}

    ${(props) =>
    props.primaryItalics &&
    css`
      color: ${props.theme.COLOURS.PRIMARY.shades[60]};
      font-style: italic;
    `}


    ${(props) =>
    props.orange &&
    css`
      color: ${props.theme.COLOURS.ORANGE.base};
    `}

  ${(props) =>
    props.accent &&
    css`
      color: ${props.theme.COLOURS.ACCENT.base};
    `}
    
  ${(props) =>
    props.large &&
    css`
      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        font-size: ${props.theme.FONTS.sizes[60]};
      }
    `}

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}

  ${(props) =>
    props.red &&
    css`
      color: ${props.theme.COLOURS.RED.base};
    `}
`;

export default StyledParagraphText;
