import Api from "./Base";
import settings from "../clientappsettings.json";

export class GetDocuments extends Api {
  baseUrl = settings.API_URL;
  requestUrl = `/vehicle/vehicleDocuments/${this.getParams().id}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };
}

export class UpdateDisplayDocumentOnline extends Api {
  baseUrl = settings.IMAGES_API_URL;
  requestUrl = `/documents/UpdateDisplayDocumentOnline`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}

export class AddVehicleDocumentation extends Api {
  baseUrl= settings.IMAGES_API_URL;
  requestUrl = `/documents/add`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    return response?.data;
  }
}

export class DeleteVehicleDocumentation extends Api {
  baseUrl= settings.IMAGES_API_URL;
  requestUrl = `/documents/delete`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    return response?.data;
  }
}