import Api from "./Base";
import settings from "../clientappsettings.json";
import { getResponseObjectProperties } from "../shared/utility";
import get from "lodash.get";

class VehicleDetailsVendor extends Api {
  baseUrl = settings.VENDORS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}


export class FetchVehicleRecordPrimaryDetails extends VehicleDetailsVendor {
  requestUrl = `/Vehicle/PrimaryDetails/${this.getParams().vehicleId}`;
  formatResponseData(response) {

    const titleDetails = [
      "regNo",
      "make",
      "model",
      "derivative"
    ];

    const keyInfoDetails = [
      "odometer",
      "odometerType",
      "odometerReadingDate",
      "dateOfRegistration",
      "fuelType",
      "transmissionType",
      "colour",
      "bodyStyle",
      "capId",
      "capCode",
      "modelYear",
      "auctionGrade",
      "shortListCount",
      "hasVmsData",
      "allowVms",
    ];

    const otherDetails = [
      "v5Status",
      "motStatus",
      "motExpiryDate",
      "hasInspection",
      "auctionGrade",
      "capCondition",
      "capValuation",
      "capValuationDate",
      "serviceHistory",
      "mostRecentInspectionId",
    ];

    const statusDetails = [
      "currentStatus",
      "defleetStartDate",
      "dateOfReturn",
      "daysInStock",
      "estimatedReturnDate",
      "returnStatus",
      "holdStatus",
      "workshopStatus",
      "holdNotes",
      "holdReason",
      "workshopReason",
      "vendorNotes",
    ];

    return {
      vehicle360Url: get(response.data, "vehicle360Url", ""),
      galleryInfo: get(response.data, "vehicleBlobUrls", []),
      titleInfo: getResponseObjectProperties(response.data, titleDetails, true),
      keyInfo: getResponseObjectProperties(response.data, keyInfoDetails, true),
      gradeOtherInfo: getResponseObjectProperties(response.data, otherDetails, true),
      statusInfo: getResponseObjectProperties(response.data, statusDetails, true),
    };
  }
}

export class FetchVehicleRecordMoreInfoDetails extends VehicleDetailsVendor {
  requestUrl = `/Vehicle/MoreInfoDetails/${this.getParams().vehicleId}`;
  formatResponseData(response) {
    const otherVehicleInfoDetails = [
      "vehicleType",
      "damagePercentage",
      "interiorColour",
      "numberOfSeats",
      "numberOfDoors",
      "engineSize",
      "power",
      "auctionGradeSiteName",
    ];
    const contractualInfoDetails = [
      "leaseType",
      "collectionOdometer",
      "collectionOdometerType",
      "collectionDate",
      "contractNumber",
      "contractStartDate",
      "contractEndDate",
      "contractTerm",
      "contractDistance",
      "originalPurchasePrice",
    ];
    const legalInfoDetails = [
      "cherishedPlate",
      "vin",
      "formerKeepers",
      "insuranceGroup",
      "emissions",
      "serviceHistory",
      "v5Status",
      "v5documentReferenceNumber",
      "assetId",
    ];
    return {
      otherVehicleInfo: getResponseObjectProperties(response.data, otherVehicleInfoDetails, true),
      contractualInfo: getResponseObjectProperties(response.data, contractualInfoDetails, true),
      legalInfo: getResponseObjectProperties(response.data, legalInfoDetails, true),
    };
  }
}
export class FetchVehicleRecordSalesInfo extends VehicleDetailsVendor {
  requestUrl = `/Vehicle/SalesInformation/${this.getParams().vehicleId}`;
  formatResponseData(response) {

    const salesInfoDetails = [
      "buyNowPrice",
      "bidStartAtPrice",
      "auctionReservePrice",
      "onlineReservePrice",
      "retailPrice",
      "conditionAtCapValuation",
      "thirdPartyValuation",
      "salesAccount",
      "salesText",
      "vatStatus",
      "saleCategory",
      "vehicleDamageTotal",
      "residualValue",
      "additionalPrepCosts",
      "biddingEndDate",
      "currentValuation",
      "biddingType",
      "biddingEnabled",
      "biddingEndDate",
    ];

    return {
      salesInfo: getResponseObjectProperties(response.data, salesInfoDetails, true),
    };
  }
}

export class FetchVehicleRecordEventHistory extends VehicleDetailsVendor {
  requestUrl = `/Vehicle/EventHistory/${this.getParams().vehicleId}`;
  formatResponseData(response) {
    return response?.data;
  }
}

export class AddEventHistory extends VehicleDetailsVendor {
  requestUrl = `/Vehicle/EventHistory/add`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatResponseData(response) {
    return response?.data;
  }
}

export class FetchVehicleRecordProfiles extends VehicleDetailsVendor {
  requestUrl = `/Vehicle/Profiles/${this.getParams().vehicleId}`;
  formatResponseData(response) {
    return response?.data;
  }
}

export class FetchVehicleRecordPurchaseInfo extends VehicleDetailsVendor {
  requestUrl = `/Vehicle/PurchaseInfo/${this.getParams().vehicleId}`;
  formatResponseData(response) {
    const purchaseInfoDetails = [
      "buyerName",
      "dateOfSale",
      "purchasePrice",
      "adminFee",
      "transportFee",
      "totalGrossInvoiceValue",
      "salesAccountName",
      "vatStatus",
      "deliveryMethod",
      "invoiceNumber",
      "disposalChannel",
      "saleCategory",
      "capValuation",
      "capValuationDate",
      "capPct",
      "invoiceId"
    ];

    const paymentInfoDetails = [
      "paymentRef",
      "paymentStatus",
      "paymentReceivedDate",
      "paymentMethod",
    ];

    const otherInfoDetails = [
      "odometer",
      "odometerType",
      "dealerCode",
      "saleCommission",
      "vehicleDamageTotal",
      "auctionRetailer",
      "rechargeTotal",
      "totalRepairCost",
      "saleReferenceCode",
      "claimId",
    ];

    const notesDetails = [
      "vendorNotes",
      "salesText",
      "damageText",
    ];

    return {
      purchaseInfo: getResponseObjectProperties(response.data, purchaseInfoDetails, true),
      paymentInfo: getResponseObjectProperties(response.data, paymentInfoDetails, true),
      otherInfo: getResponseObjectProperties(response.data, otherInfoDetails, true),
      notesInfo: getResponseObjectProperties(response.data, notesDetails, true),
    };
  }
}

export class FetchVehicleRecordBiddingInfo extends VehicleDetailsVendor {
  requestUrl = `/Bidding/BiddingInfo`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatResponseData(response) {
    return {
      bids: get(response.data, "bids", []),
      acceptedBid: get(response.data, "acceptedBid"),
      salesInfo: get(response.data, "salesInformation"),
      showPriceWhenBidding: response.data.showPriceWhenBidding,
      showReserveWhenBidding: response.data.showReserveWhenBidding,
      allowBidsBelowReserve: response.data.allowBidsBelowReserve,
      allowBuyItNow: response.data.allowBuyItNow,
    };
  }
}

export class FetchVehicleRecordInspections extends VehicleDetailsVendor {
  requestUrl = `/Vehicle/Inspections/${this.getParams().vehicleId}`;
  formatResponseData(response) {
    return response?.data;
  }
}

export class FetchVehicleLocation extends VehicleDetailsVendor {
  requestUrl = `/Vehicle/VehicleLocation`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatResponseData(response) {
    return response?.data;
  }
}

export class AcceptBid extends VehicleDetailsVendor {
  requestUrl = `/Bidding/AcceptBid`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };
}

export class DeclineBid extends VehicleDetailsVendor {
  requestUrl = `/Bidding/DeclineBid`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };
}

export class FetchAdditionalEquipment extends VehicleDetailsVendor {
  requestUrl = `/Vehicle/AdditionalEquipment/${this.getParams().vehicleId}`;
  formatResponseData(response) {
    return response?.data;
  }
}

export class UpdateAdditionalEquipment extends VehicleDetailsVendor {
  method = "POST";
  requestUrl = `/Vehicle/AdditionalEquipment/Update`;
  formatResponseData(response) {
    return response?.data;
  }
}