import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";
 
const EditPen = ({ width, height, strokeColour, fillColour }) => {
  const theme = useContext(ThemeContext);
  const fill = fillColour ? fillColour : theme.COLOURS.PRIMARY.base;
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="feather feather-edit-pen"
      viewBox="0 0 24 24"
    >
      <path d="M12 20h9M16.5 3.5a2.121 2.121 0 013 3L7 19l-4 1 1-4L16.5 3.5z"></path>
    </svg>
  );
};
 
EditPen.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  fillColour: PropTypes.string,
};
 
EditPen.defaultProps = {
  width: "24",
  height: "24",
};
 
export default EditPen;