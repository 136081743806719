import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ButtonLink from "../../components/ButtonLink";
import Icon from "../../components/Icon";
import ProgressBar from "../../components/ProgressBar";
import { TrashIconContainer } from "../../components/ClaimItem/index";
import {
  deleteShowroomModeSettingsLogo,
  saveShowroomModeSettingsBlockLogo,
} from "../../../state/reducers/buyerInformation";
import {
  saveSupportingBlockDocument,
  deleteSupportingDocument,
} from "../../../state/reducers/campaignManagement";
import BidInputError from "../../components/BidInput/child-components/BidInputError";
import { errorMessages } from "../../components/ShowroomModeSettings/const";
import { fileUploadConst } from "./const";

const FileUpload = ({
  documentOrImage = {},
  callingArea,
  disabled,
  setShowLogoDisabled,
  setCampaignDocumentsDisabled,
  id,
  type,
  userType,
  disabledTrashCan,
  sourceFolder,
  setParentFormButtonDisabled,
  disableBlockUpload,
  dragDropData,
  getDownloadLink,
}) => {
  const dispatch = useDispatch();
  const {
    progress = 0,
    beginingOfTheBlock,
    endOfTheBlock,
    noOfBlocks,
    uploaded = false,
    duplicateDocument = false,
    blockNo,
    file = null,
    fileName,
    url,
  } = documentOrImage;

  const handleFile = () => {
    if (file) {
      let block = file.slice(beginingOfTheBlock, endOfTheBlock);
      const percentage = (blockNo / noOfBlocks) * 100;

      const data = {
        fileName: file.name,
        blockNo: blockNo,
        blockSize: block.size,
        finalBlock: blockNo === noOfBlocks,
        block: block,
        percentage: percentage,
        duplicateDocument: false,
      };
      onSaveDocumentOrImage(data, id);
    }
  };

  const onSaveDocumentOrImage = (data, id) => {
    if (callingArea === fileUploadConst.SHOWROOMMODE) {
      setShowLogoDisabled(true);
      dispatch(saveShowroomModeSettingsBlockLogo(data, id));
    } else if (callingArea === fileUploadConst.CAMPAIGNMANAGEMENT) {
      dispatch(saveSupportingBlockDocument(data, id, sourceFolder));
    }
  };

  const onDeleteDocumentOrImage = (id, fileName, userType, type) => {
    if (callingArea === fileUploadConst.SHOWROOMMODE) {
      setShowLogoDisabled(false);
      dispatch(deleteShowroomModeSettingsLogo(fileName, userType, type));
    } else if (callingArea === fileUploadConst.CAMPAIGNMANAGEMENT) {
      setParentFormButtonDisabled(true);
      dispatch(
        deleteSupportingDocument(id, fileName, userType, type, sourceFolder)
      );
    }
  };

  useEffect(() => {
    if (!uploaded && !duplicateDocument && !disableBlockUpload) {
      handleFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentOrImage]);

  return (
    <div>
      <div>
        {duplicateDocument ? (
          <div className="failedItems">
            <ButtonLink
              className="failedFile"
              key={fileName}
              listItem
              name={fileName}
            />
            <BidInputError errorMessage={errorMessages.DUPLICATE_FILE} />
          </div>
        ) : disableBlockUpload ? (
          <ButtonLink
            key={dragDropData.documentName}
            listItem
            primary
            name={dragDropData.documentName}
            action={() => {
              window.open(
                getDownloadLink(
                  dragDropData.vehicleDocumentId,
                  dragDropData.documentName
                )
              );
            }}
          />
        ) : (
          <ButtonLink
            key={fileName}
            listItem
            primary
            name={fileName}
            disabled={!url}
            action={() => {
              window.open(url, "_blank");
            }}
          />
        )}
      </div>

      {!disableBlockUpload && !duplicateDocument && !disabledTrashCan && (
        <div>
          {uploaded ? (
            <TrashIconContainer
              className="drag-drop__trash"
              onClick={() =>
                onDeleteDocumentOrImage(id, fileName, userType, type)
              }
            >
              <Icon type="trash" width="24" height="24" />
            </TrashIconContainer>
          ) : (
            <ProgressBar value={progress} />
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
