export const errorMessages = {
    INVALID_FILE_FORMAT: "Invalid file format selected",
    FILE_TOO_LARGE: "File too large. Max file size of {0}MB",
    DUPLICATE_FILE: "This file has been used previously. File has been removed."
  };

  export const primaryParagraphTextConst = {
    PDF_FILES: "Drag and Drop Your pdf File Or Click Here"
  }

  export const secondaryParagraphTextConst = {
    PDF_FILES: "(Only *.pdf files will be accepted)"
  }

  export const modalTitle = {
    DOCUMENTATION: "Upload Documents:"
  }

  export const modalSubtitle = {
    DOCUMENTATION: "Existing Documents:"
  }