import React, { useContext, useState } from "react";
import Icon from "../../Icon";
import { ThemeContext } from "styled-components";

const DatePickerInput = (props) => {
  const theme = useContext(ThemeContext);
  const [iconColor, setIconColor] = useState(theme.COLOURS.ACCENT.base);

  return (
    <div className="input-search">
      <input {...props} onChange={props.onChange} />
      <span
        className="input-search__icon"
        {...props}
        onMouseOver={() => {
          setIconColor(theme.COLOURS.SECONDARY_ACCENT.base);
        }}
        onMouseOut={() => {
          setIconColor(theme.COLOURS.ACCENT.base);
        }}
      >
        <Icon
          type="calendar"
          strokeColour={theme.COLOURS.WHITE}
          strokeWidth="2"
          width="43"
          height="43"
          fillColour={iconColor}
        />
      </span>
    </div>
  );
};

export default DatePickerInput;
