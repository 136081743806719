import React, { Fragment, useState, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import GenericConfirmInsert from "../../components/GenericConfirmInsert";
import {acceptBid, declineBid, clearActiveConfirmModalOptions } from "../../../state/reducers/vehicleDetailsVendor";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../views/theme/breakpoints";
import { deleteVehicleDocumentation } from "../../../state/reducers/vehicle";
import LoaderModalInsert from "../LoaderModalInsert";

const VendorVehicleRecordGenericConfirmModal = () => {
    const dispatch = useDispatch();

    const [isActionProcessing, setIsActionProcessing ] = useState(false);

    const { type, data } = useSelector(
        (state) => state.vehicleDetailsVendor.activeConfirmModalOptions,
      );
     // type is useful when there are same type of actions in multiple area, unless people name their actions right and unique

    const isDesktop = useMediaQuery({
        query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
      });
    
    const getBodyTextToDisplay = (action) => {
        switch (action) {
          case "accept-bid":
              return `You are about to accept a bid of ${data.bidAmount} from ${data.buyerName}, are you sure?`;
          case "decline-bid":
            return `You are about to decline a bid of ${data.bidAmount} from ${data.buyerName}, are you sure?`;
          case "delete-document":
            return `Are you sure you want to delete ${data.documentName}?`
            default:
              return "";
        }
    };
  
    const onConfirm = () => {
      if (data.action === "accept-bid") {
          dispatch(acceptBid(data.vehicleReference,data.bidId));
      } else if (data.action === "decline-bid") {
          dispatch(declineBid(data.vehicleReference,data.bidId));
      } else if (data.action === "delete-document") {
        dispatch(deleteVehicleDocumentation(data.vehicleReference, data.vehicleDocumentId));
      }
      setIsActionProcessing(true);
    };
  
    const onCancel = () => {
      dispatch(closeModal());
      dispatch(clearActiveConfirmModalOptions());
    };

    return (
      <Fragment>
        <ModalInsert width={isDesktop ? "55rem" : "100%"}>
        {isActionProcessing ? (
          <LoaderModalInsert
            title={"Processing your request"}
            content={"We are just processing your request, please wait."}
          />
        ) : (
          <GenericConfirmInsert
            headerText="Are you sure?"
            bodyText={getBodyTextToDisplay(data.action)}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
        )}
        </ModalInsert>
      </Fragment>
    );
  };
  
  export default VendorVehicleRecordGenericConfirmModal;