import React from "react";
import { useSelector } from "react-redux";
import { 
  ACCEPTBID_HEADERTEXT, 
  ACCEPTEDBID_BODYTEXT,
  DECLINEBID_HEADERTEXT, 
  DECLINEBID_BODYTEXT,
  DOCUMENT_HEADERTEXT,
  DOCUMENT_BODYTEXT, 
} from "./const";
import GenericTransitionModal from "../GenericTransitionModal";

const VendorVehicleRecordGenericTransitionModal = () => {

  const { type, data } = useSelector(
    (state) => state.vehicleDetailsVendor.activeConfirmModalOptions,
  );

  const getText = (action) => {
    switch (action) {
      case "accept-bid":
        return {
          headerText: ACCEPTBID_HEADERTEXT, 
          bodyText: ACCEPTEDBID_BODYTEXT
        };
      case "decline-bid":
        return {
          headerText: DECLINEBID_HEADERTEXT, 
          bodyText: DECLINEBID_BODYTEXT
        };
        case "delete-document":
          return {
            headerText: DOCUMENT_HEADERTEXT,
            bodyText: DOCUMENT_BODYTEXT
          }
      default:
        return {
          headerText: "", 
          bodyText: ""
        };
    }
  };

  const { headerText, bodyText } = getText(data?.action);

  return (
    headerText ? (
      <div>
        <GenericTransitionModal
          headerText={headerText}
          bodyText={bodyText}
        />
      </div>
    ): null
  );
};

export default VendorVehicleRecordGenericTransitionModal;
